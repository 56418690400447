import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const getTitleText = (extraChar) => {
    // const char = "\u2877"; // "\u2876";
    extraChar = extraChar ? extraChar : "\u2877";
    return `Career Advisor ${extraChar}`;
}

const chars = ["\u2876", "\u2877", "\u2878", "\u2879"];
let charsIdx = 0;
let howOften = 1500;

// TODO: style this differently -- make the Blue be some other color
const ButtonAppBar = () => {
  const [title, setTitle] = React.useState(getTitleText(""));
  const changeTitleChar = () => {
      console.log(`${charsIdx}: ${chars[charsIdx]}`);
      // setTitle(getTitleText(chars[charsIdx]));
      setTitle(charsIdx);
      charsIdx++;
      if (charsIdx >= chars.length) {
          charsIdx = 0;
      }
      setTimeout(changeTitleChar, howOften);
  }


/*  React.useEffect(() => {
      setTitle("blah");
      let defaultValues = {};
      defaultValues.title = getTitleText("");
  },[title]);*/

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>{/* menu may come later
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>*/}
          <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
            { title }
          </Typography>
          <Button color="inherit">
          {/* logins will not be needed until later --- Login*/}
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default ButtonAppBar;
