import * as React from 'react';
import { useRef } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import Stripe from './Stripe.js';
import reCAPTCHA from 'react-google-recaptcha';


let stepContext = {
    handleOnJobTitleChange: "",
    handleOnExperienceChange: "",
    captchaRef: "",
    waitingForAdvise: "", // function
    advise: {
        "CoverPhotoURL": "",
        "CoverPhotoURLCharacterCount": 0,
        "CoverPhotoText": "",
        "CoverPhotoTextCharacterCount": 0,
        "HeadLineText": "",
        "HeadLineTextCharacterCount": 0,
        "AboutSection": "",
        "AboutSectionCharacterCount": 0,
        "Experience": "",
        "ExperienceCharacterCount": 0,
        "Posts": "",
        "PostsCharacterCount": 0,
    },
}

const boxMaxWidth = 800; // original was 400


let context = {
    jobTitle: "",
    experience: "",
}

const handleOnJobTitleChange = (event) => {
    context.jobTitle = event.target.value;
    console.log(`context.jobTitle: ${context.jobTitle}`)
}

const handleOnExperienceChange = (event) => {
    context.experience = event.target.value;
    console.log(`context.experience: ${context.experience}`)
}


const getAdvise = (setWaitingForAdvise) => {
    setWaitingForAdvise(true);
    console.log("Getting advice for context:", context);

let token = "abc"; // TODO: should we need a token...
    // if NODE_ENV==dev -- let url='http://localhost:8080/api/'
    let url='https://resume.10print.co/api/'
    fetch(url,{
        crossDomain:true,
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: new Headers({
            // 'Authorization': `Bearer: ${token}`,
            'Accept': 'application/json',
            // 'Access-Control-Allow-Origin':'http://localhost:3000/',
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            newJobtitle: 'React POST Request Example',
            experience: 'I worked for 15 years at CBS as a marketing manager',
        }),
    })
        .then(resp => resp.json())
        .then(js => {
            stepContext.advise = js;

            stepContext.advise.CoverPhotoURL = js.CoverPhotoURL;
            stepContext.advise.CoverPhotoURLCharacterCount = js.CoverPhotoURLCharacterCount;
            stepContext.advise.CoverPhotoText = js.CoverPhotoText;
            stepContext.advise.CoverPhotoTextCharacterCount = js.CoverPhotoTextCharacterCount;
            stepContext.advise.HeadLineText = js.HeadLineText;
            stepContext.advise.HeadLineTextCharacterCount = js.HeadLineTextCharacterCount;
            stepContext.advise.AboutSection = js.AboutSection;
            stepContext.advise.AboutSectionCharacterCount = js.AboutSectionCharacterCount;
            stepContext.advise.Experience = js.Experience;
            stepContext.advise.ExperienceCharacterCount = js.ExperienceCharacterCount;
            stepContext.advise.Posts = js.Posts;
            stepContext.advise.PostsCharacterCount = js.PostsCharacterCount;

            console.log("HERE IS THE ADIVSE >>>>:", stepContext.advise)
            console.log(stepContext.advise.HeadLineText);
        })
        .then(js => console.log(js))
        .then(js => { setWaitingForAdvise(false) });
}

// --------------------------------------------------------------

const getIntroComic = () => {
    return (
        <img
            src="https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c?w=164&h=164&fit=crop&auto=format&dpr=2"
            alt="welcome-introduction-comic"
            loading="lazy"
        />);
}


const getIntroDescr = (stepContext) => {
    return (
        <>
        Hey there! I am Ray! &#x1F44B; <br/>
        Welcome to my office! &#x1F3E0;<br/>
        I will be your Career Advisor. &#128105; <br/>
        I am here to help you with your career. &#x1F9D1; &#x200D; &#x1F52C;<br/>
        Here is what we can accomplish today:<br/>
        &nbsp;&nbsp;Tell me about your career aspirations & past experience...
        &nbsp;&nbsp;I will build your LinkedIN profile will increase your chances of landing that dream job.<br/>
        &nbsp;&nbsp;You will also get 5 LinkedIN posts so you can get<br/>
        the attention you deserve from recruiters!<br/>
        <reCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={stepContext.captchaRef}
        />
       </>);
}

// --------------------------------------------------------------

const getNewTitleComic = () => {
    return (
        <img
            src="https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c?w=164&h=164&fit=crop&auto=format&dpr=2"
            alt="new-job-title-comic"
            loading="lazy"
        />);
}

const getNewTitleDescr = (stepContext) => {
    // Pardon me for asking, but what's the title you are aiming for in your next job?
    return (
        <>
        First, tell me about the new job you want.<br/>
        What is the <strong>now job title</strong> you are aiming for?<br/><br/>
        <TextField
          id="outlined-helperText"
          label="New job title"
          defaultValue="Product Manager"
          helperText="Enter the new title you want to have at your new job"
          onChange={ stepContext.handleOnJobTitleChange }
          fullWidth
        />
        </>
    );
}

const getNewTitleForm = () => {
    return <input />;
}


// --------------------------------------------------------------

const getExperienceComic = () => {
    return (
        <img
            src="https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c?w=164&h=164&fit=crop&auto=format&dpr=2"
            alt="work-experience-comic"
            loading="lazy"
        />);
}

const getExperienceDescr = (stepContext) => {
    return (<>
            Now tell me about your career so far! What is your experience?<br/>
            What jobs did you have in the past?<br/>
            What's the company name?<br/>
            What did you do there?<br/>
            When did you start at that company?<br/>
            How long were you there?<br/>
            Tell me about your most recent experience!<br/>
            Don't forget to mention the start and end dates!<br/>
            Give me all the details on what you achieved in each role!<br/>

            <TextField
              onChange={ stepContext.handleOnExperienceChange }
              id="outlined-multiline-static"
              label="Work Experience"
              helperText="Enter all the jobs you have had, dates, and what you achieved"
              multiline
              rows="15"
              defaultValue="I was a Product Manager at ACME 2000 until 2020 where I shipped the following products: ..."
              margin="normal"
              variant="outlined"
              fullWidth
            />
        </>);
}


// --------------------------------------------------------------

const getAdviseComic = (stepContext) => {
    if (stepContext.waitingForAdvise) {
        return;
    }

    return (
        <img
            src="https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c?w=164&h=164&fit=crop&auto=format&dpr=2"
            alt="advise-comic"
            loading="lazy"
        />);
}

const getAdviseDescr = (stepContext) => {
    if (stepContext.waitingForAdvise) {
        return <CircularProgress />;
    }

    // TODO: The question here is - should we show everything on one page
    // Or break this up in a few steps
    // TODO: can we show the firsnt 50% of the text and fog the rest?
    // TODO:
    return (
        <>
            <h3>Ok! Here is what I think...</h3>
            <ul>
                <li><strong>Change your Headline to:</strong> { getHeadLineText(stepContext) }</li>
                <li><strong>Change your About Section to this:</strong>{ getAboutSection(stepContext) }</li>
                <li><strong>Here are some tips on improving your experience section: </strong>{ getExperience(stepContext) }</li>
                <li><strong>Posts:</strong>{ getPosts(stepContext) }</li>
            </ul>
        </>
    );

}

function getCoverPhotoURL() {
    if(stepContext && stepContext.advise && stepContext.advise.CoverPhotoURL){
        return stepContext.advise.CoverPhotoURL;
    }
}

function getCoverPhotoURLCharacterCount() {
    if(stepContext && stepContext.advise && stepContext.advise.CoverPhotoURLCharacterCount){
        return stepContext.advise.CoverPhotoURLCharacterCount;
    }
}

function getCoverPhotoText() {
    if(stepContext && stepContext.advise && stepContext.advise.CoverPhotoText){
        return stepContext.advise.CoverPhotoText;
    }
}

function getCoverPhotoTextCharacterCount() {
    if(stepContext && stepContext.advise && stepContext.advise.CoverPhotoTextCharacterCount){
        return stepContext.advise.CoverPhotoTextCharacterCount;
    }
}

function getHeadLineText() {
    if(stepContext && stepContext.advise && stepContext.advise.HeadLineText){
        return stepContext.advise.HeadLineText;
    }
}

function getHeadLineTextCharacterCount() {
    if(stepContext && stepContext.advise && stepContext.advise.HeadLineTextCharacterCount){
        return stepContext.advise.HeadLineTextCharacterCount;
    }
}

function getAboutSection() {
    if(stepContext && stepContext.advise && stepContext.advise.AboutSection){
        let splitChar = "►";
        return stepContext.advise.AboutSection.split(splitChar).map(line => <p> { splitChar + " " + line} </p>)
    }
}

function getAboutSectionCharacterCount() {
    if(stepContext && stepContext.advise && stepContext.advise.AboutSectionCharacterCount){
        return stepContext.advise.AboutSectionCharacterCount;
    }
}

function getExperience() {
    if(stepContext && stepContext.advise && stepContext.advise.Experience){
        return stepContext.advise.Experience;
    }
}

function getExperienceCharacterCount() {
    if(stepContext && stepContext.advise && stepContext.advise.ExperienceCharacterCount){
        return stepContext.advise.ExperienceCharacterCount;
    }
}

function getPosts() {
    if(stepContext && stepContext.advise && stepContext.advise.Posts){
        // return stepContext.advise.Posts;
        let posts = stepContext.advise.Posts;
        return posts.split("\n").map(post => <p> {post} </p>)
    }
}

function getPostsCharacterCount() {
    if(stepContext && stepContext.advise && stepContext.advise.PostsCharacterCount){
        return stepContext.advise.PostsCharacterCount;
    }
}


// --------------------------------------------------------------

const getPDFComic = () => {
    return (
        <img
            src="https://images.unsplash.com/photo-1613061527119-56ad37b8a581?w=164&h=164&fit=crop&auto=format&dpr=2"
            alt="download-pdf-comic"
            loading="lazy"
        />);
}

const getPDFDescr = (stepContext) => {
    return `Give me a doller and I will send you a PDF with this advise so you can have it forever!`;
}


// --------------------------------------------------------------

const getFinalComic = () => {
    return (
        <img
            src="https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c?w=164&h=164&fit=crop&auto=format&dpr=2"
            alt="final-comic"
            loading="lazy"
        />);
}

const getFinalDescr = (stepContext) => {
    return `Thank you!`;
}

// --------------------------------------------------------------

const showThinkingModal = () => {
    // Hm... Let me think for a moment.
    // How are we going to get you that new job...
    return ({/*
             // TODO: Is there material design modal? - show a spinner of some kind...
              */});
}

// --------------------------------------------------------------

const steps = [
  {
    label: 'Heya',
    // comicStrip: getIntroComic,
    description: getIntroDescr,
  },
  {
    label: `Your new job title`,
    // comicStrip: getNewTitleComic,
    description: getNewTitleDescr,
  },
  {
    label: 'Your work experience',
    // comicStrip: getExperienceComic,
    description: getExperienceDescr,
  },
  {
    label: 'Advise for you!',
    comicStrip: getAdviseComic,
    description: getAdviseDescr,
  },
  {
      // TODO: This is going to need a PDF download tool
      // this could be done async as soon as the results are ready
      // prep the PDF asd send it to the browser.
    label: 'Save it',
    form: () => Stripe(),
    comicStrip: getPDFComic,
    description: getPDFDescr,
  },
];


// =============================================

export default function VerticalLinearStepper(stepFns) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [waitingForAdvise, setWaitingForAdvise] = React.useState(false);
  const captchaRef = useRef(null)

  const handleNext = (event) => {
    console.log("Next step is going to be: ", activeStep + 1);
    if (activeStep == 3) {
        console.log("TODO: show thinking modal!");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (activeStep === 2) {
      getAdvise(setWaitingForAdvise);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const getStepName = (index, steps) => {
      // Next was Continue
      if (index === steps.length - 1) {
          return 'Pay & Get PDF'; /*Finish*/
      }
      if (index === steps.length -3) {
          return 'Advise me';
      }
      return 'Next' /*Continue*/
  }

  const getBackButton = (index, steps, waitingForAdvise) => {
    if (index === steps.length - 1) {
      return;
    }
    const buttonText = `Back`;
    return (
          <Button
              disabled={index === 0 || waitingForAdvise}
              onClick={handleBack}
              sx={{ mt: 1, mr: 1 }}
          >
              { buttonText }
          </Button>);
  }

  stepContext.captchaRef = captchaRef;
  stepContext.waitingForAdvise = waitingForAdvise;

  return (
    <Box sx={{ maxWidth: boxMaxWidth }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last question!</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              { step.comicStrip && step.comicStrip(stepContext) }
              <Typography>{ step.description(stepContext) }</Typography>
              { step.form && step.form() }
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    disabled={waitingForAdvise}
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    { getStepName(index, steps) }
                  </Button>
                  { getBackButton(index, steps, waitingForAdvise) }
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>{ getFinalDescr() }</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
