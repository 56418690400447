import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
  <React.Fragment>

    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          You can actually subscribe for this
      </Typography>
      <Typography variant="h5" component="div">
        {bull} For $3 a month we will send you a random golden or silver age comic book
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        you don't get much of a choice of which magazine
      </Typography>
      <Typography variant="body2">
          but you're gonna love it
        <br />
        {'"promise"'}
      </Typography>
    </CardContent>

    <CardActions>
      <Button size="small">Learn More</Button>
    </CardActions>

  </React.Fragment>
);

export default function Step1() {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}
