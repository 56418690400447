import logo from './logo.svg';
import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import HorizontalLinearStepper from './HorizStepper.js';
// import ButtonAppBar from './ButtonAppBar.js';
// import TextMobileStepper from './BetterStepper.js';
import TopDrawerAppBar from './TopBar.js';
import VerticalLinearStepper from './VertStepper.js';
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';
import Cookies from 'js-cookie';

import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step3 from './Step3.js';

import LinkedInPage from './LinkedIN.js';

let stepFns = {
    0: Step1,
    1: Step2,
    2: Step3,
}

let waitingForResults = false;

//Initialize GA4
ReactGA.initialize("G-GWWGG6DP2H");

function SimplePaper() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 128,
          height: 128,
        },
      }}
    >
      <Paper elevation={0} />
      <Paper />
      <Paper elevation={3} />
    </Box>
  );
}


function App() {
  const cookieAdvisor = Cookies.get('Advisor');
  console.log("cookieAdvisor: ", cookieAdvisor);
  cookieAdvisor || Cookies.set('Advisor', 'value', { expires: 999 });
  SendAnalytics();
  return (
    <div className="App" style={{padding: 10}} >
        {TopDrawerAppBar()}
        {/*ButtonAppBar()*/}
        {/*renderButton()*/}

        {/*HorizontalLinearStepper(stepFns)*/}
        {/*LinkedInPage()*/}
        {VerticalLinearStepper(stepFns)}
        {/*TextMobileStepper()*/}
{/*
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload!
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
*/}
    </div>
  );
}

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

export default App;
